import { createSlice } from "@reduxjs/toolkit";

export const mainDashBoardSlice=createSlice({
    name:"mainDashBoardSlice",
    initialState:{
        submitLoading:false,
        tableLoading:false,
        successSnack:false,
        errorSnack:false,
        snackMsg:"",
    },
    reducers:{
        setSubmitLoading:(state,action)=>{
            state.submitLoading=action.payload
        },
        setTableLoadingGlobal:(state,action)=>{
            state.tableLoading=action.payload
        },
        setSuccessSnack:(state,action)=>{
            state.successSnack=action.payload
        },
        setErrorSnack:(state,action)=>{
            state.errorSnack=action.payload
        },
        setSnackMsg:(state,action)=>{
            state.snackMsg=action.payload
        },
    }
})

export const {setSubmitLoading,setTableLoadingGlobal,setSuccessSnack,setErrorSnack,setSnackMsg}=mainDashBoardSlice.actions

export default mainDashBoardSlice.reducer