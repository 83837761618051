/** @format */

import logo from "./logo.svg";
import "./App.css";
import Login from "./Components/Login/Login";
import LeftMenu from "./Components/Dashboard/LeftMenu/LeftMenu";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { CircularProgress, Modal, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "./Redux/HomeDashboard/mainDashBoardSlice";

function App() {

  const dispatch=useDispatch()
  const { successSnack, errorSnack, snackMsg,submitLoading } = useSelector(
    (state) => state.mainDashBoardSlice
  );

  const handleCloseSnackBar=()=>{
    dispatch(setSnackMsg(""))
    dispatch(setSuccessSnack(false))
    dispatch(setErrorSnack(false))
  }
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="dashboard/*" element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>

      <Snackbar
        open={successSnack || errorSnack}
        autoHideDuration={50000}
        onClose={handleCloseSnackBar}
        message={snackMsg || "---"}
        sx={{
          "& .MuiSnackbarContent-root": { 
            backgroundColor:successSnack?"#14a212":"#c62424",
            color: "#fff",
            minWidth:"fit-content" 
          }
        }}
      />

       <Modal open={submitLoading}  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
         <CircularProgress color="secondary" size={"80px"}/>

       </Modal>
    </div>
  );
}

export default App;
