import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../../Redux/Store";
import { customer_register_initial_form } from "./customerRegistrationSlice";
import { get_my_plans_singleView } from "../../../Redux/RegisterdList/registerdUserSlice";
import { TextField, Button, Box } from "@mui/material";

export const CustomerRegistrationPopup = () => {
  const navigateTo = useNavigate();

  const [popForm, setPopupForm] = useState({
    email: "",
    mobile: "",
    companyName: "",
  });

  const [errors, setErrors] = useState({});

  const getPopupForm = (key) => (e) => {
    const { value } = e.target;
    setPopupForm({ ...popForm, [key]: value });

    // Clear error when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
  };

  

  const clickNextBtn = () => {
    let newErrors = { ...errors };
  
    if (!popForm.email.trim()) {
      newErrors.email = "Email is required";
      setErrors(newErrors);
      return;  // Stop function execution here
    }
  
    if (!popForm.mobile.trim()) {
      newErrors.mobile = "Mobile number is required";
      setErrors(newErrors);
      return;  // Stop function execution here
    }
  
    if (!popForm.companyName.trim()) {
      newErrors.companyName = "Company name is required";
      setErrors(newErrors);
      return;  // Stop function execution here
    }
  
    // If no errors exist, proceed with form submission
    setErrors({}); // Clear errors when all fields are valid
    store.dispatch(customer_register_initial_form({ initialFormData: popForm }));
    navigateTo("/dashboard/customerRegistration/form");
  };
  

  useEffect(() => {
    store.dispatch(get_my_plans_singleView({ singleViewData: undefined }));
    localStorage.removeItem("packageId");
  }, []);

  return (
    <Box className="global-container" sx={{ height: "88vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box className="customer-reg-popup" sx={{ padding: 3, width: 400, boxShadow: 3, backgroundColor: "white", borderRadius: 2 }}>
        <p style={{ textAlign: "left", fontSize: "1rem", fontWeight: 600 }}>Customer Registration</p>

        {/* Email Input */}
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={popForm.email}
          onChange={getPopupForm("email")}
          error={!!errors.email}
          helperText={errors.email}
        />

        {/* Mobile Input */}
        <TextField
          label="Mobile"
          variant="outlined"
          fullWidth
          margin="normal"
          value={popForm.mobile}
          onChange={getPopupForm("mobile")}
          error={!!errors.mobile}
          helperText={errors.mobile}
        />

        {/* Company Name Input */}
        <TextField
          label="Company Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={popForm.companyName}
          onChange={getPopupForm("companyName")}
          error={!!errors.companyName}
          helperText={errors.companyName}
        />

        {/* Submit Button */}
        <button onClick={clickNextBtn} className='global-button-contained-blue' style={{width:"97%"}}>Next</button>
      </Box>
    </Box>
  );
};
