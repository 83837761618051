/** @format */

import TopContainer from "../CustomComponent/TopContainer";
import "../../../Css/Package and Plans/packageAndPlans.css";
import { Autocomplete, Checkbox, Dialog, IconButton, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React, { useEffect, useState } from "react";
import ModuleDuration from "../CustomComponent/ModuleDuration";
import PackageDuration from "../CustomComponent/PackageDuration";
import { addPackageAPICall, addPlanAPICall, currentLoactionAPI, deletePackageAPICall, editPackageAPICall, generatePackageIdAPICall, getAllSubModuleAPICall, getAllSubModuleById, getModuleDurationAPICall, singlePackageAPICall, viewAllPackageList, viewPackageDuration, viewPlanAPICall } from "../../../API/AddPackage/AddPackageAPI";
import { useSelector } from "react-redux";
import CountryListWithCode from "../../../Assets/JSON/CountryLIstWithCode.json"
import { SubModuleDuration } from "../CustomComponent/SubModuleDuration";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AddSubModule } from "../CustomComponent/AddSubModule";
import uploadIcon from "../../../Assets/Images/uploadIcon.svg"
import { ContentPopup } from "./ContentPopup";
import SuccessSnackbar from "../../SingleCompoents/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../SingleCompoents/SnackBars/ErrorSnackbar";
import { filterObjFromList } from "../../../Assets/JS/GeneraalFunctions";
import { getTaxListApiCall } from "../../../API/Tax/Tax";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PackageAndPlans = () => {
  const ErpTypes=[
    {name:"ERP-Autoparts"},
    {name:"ERP-Restaurant"},
    {name:"ERP-Textile"},
    {name:"ERP-Logistics"},
    {name:"ERP-Hotel"},
    {name:"ERP-Education"},
    {name:"ERP-Aviation"},
    {name:"ERP-Hospital"},
    {name:"ERP-Supermarket"},
    {name:"ERP-General Accounts"},
  ]
  const durationType=[
    {name:"Month"},{name:"Year"}
  ]

  //useSelector
  const packageId=useSelector((state)=>state.generatePackageIdSlice.value)
  // package list
  const packageList=useSelector((state)=>state.generatePackageIdSlice?.value1)
  // single package
  const singlePackage=useSelector((state)=>state.generatePackageIdSlice?.singlePackage)
  // package duration list
  const packageDurationList=useSelector((state)=>state.generatePackageIdSlice.value2)
  //moduleDuration list
  const moduleDurationList=useSelector((state)=>state.generatePackageIdSlice.value3)
  // subMOduleList
  const subModuleList=useSelector((state)=>state.generatePackageIdSlice.value4)
  // submoduleListById
  const subModuleIdById=useSelector((state)=>state.generatePackageIdSlice.value5)
  // view plan 
  const planList=useSelector((state)=>state.generatePackageIdSlice.plan)
  const TaxList = useSelector((state) => state.TaxListSlice.value);
 

  // input states
  const [openModule, setOpenModule] = useState(false);
  const [openPackage, setOpenPackage] = useState(false);
  const [openSubModule,setOpenSubModule]=useState(false)
  const [isModify,setIsModify]=useState(false)
  const [subModuleArray,setSubModuleArray]=useState([])
  const [contentToBackend,setContentToBackend]=useState([])
  const [planType,setplanType]=useState("")
  
  const addPackageForm={
    country:null,
    typeOfErp:null,
    distributionPrice:'',
    packageDuration:durationType[1],
    backupPrice:'',
    module:null,
    subModule:null,
    addingLocationPrice:0,
    sellingPrice:0,
    durationType:"",
    discription:'',
    tax:0,
    cess:0,
    planType:null,
    isMobilePackage:false,
    planPrice:0,
    mobilepackagePrice:"",
    mobileLocPrice:"",
    color:"",
    image:null,
    imageName:"",
    tax:[],
    released:false
  }
  const [addPackage,setAddPackage]=useState(addPackageForm)
  const [moduleArray,setModuleArray]=useState([])
  const [resultArray, setResultArray] = useState([]);
  const [data, setData] = useState([]);
  const [country,setCountry]=useState()
  const [addPlan,setAddPlan]=useState(false)
  const [addSubModule,setAddSubModule]=useState(false)
  const [contentPopup,setContentPopup]=useState(false)
  const [image,setImage]=useState()
  const [imageName,setImageName]=useState('')
  const [editedId,setEditedId]=useState()
  const [snackBarStates,setSnackBarStates]=useState({
    success:false,
    error:false,
    snackMsg:"",
  })
  const [updatedTaxArray, setUpdatedTaxArray] = useState([]);
  const [transactionId,setTransactionId]=useState('')
  useEffect(()=>{
    setUpdatedTaxArray(TaxList?.map((obj) => {
      return obj;
    }) ?? [])
  },[TaxList])
  useEffect(()=>{
    setTransactionId(`${packageId?.prefix}${packageId?.transNo}`)
  },[packageId])
  console.log(TaxList);
  console.log(updatedTaxArray);
  console.log(addPackage);
 
  const handleCloseSuccess=()=>()=>{
    setSnackBarStates({...snackBarStates,error:false,success:false})
}
  

  const updateListner=()=>{
    setIsModify(!isModify)
  }

  const clickModuleDuration = () => {
    setOpenModule(true);
  };
  const clickPackageDuration = () => {
    setOpenPackage(true);
  };
  const closeConfirmDialog = () => {
    setOpenModule(false);
    setOpenPackage(false);
  };
  const clickSubModuleDuration=()=>{
    setOpenSubModule(true)
  }
  const closeSubModuleDuration=()=>{
    setOpenSubModule(false)
  }


  // useEffect
  useEffect(()=>{
    
    generatePackageIdAPICall()
    viewAllPackageList()
    viewPackageDuration()
    // getModuleDurationAPICall()
    // getAllSubModuleAPICall()
    viewPlanAPICall()

  },[isModify])

  useEffect(()=>{
    getTaxListApiCall({country:addPackage?.country?.name})
  },[addPackage?.country])


  // useEffect(()=>{
  //   viewAllPackageList({country:country?.name})
  // },[country])


  // useEffect(()=>{
  //   getAllSubModuleById({moduleId:addPackage?.module})
  // },[addPackage?.module])

// 
  // onChange function
const getAddPackage=(key)=>(e,newValue)=>{
  //  const {value}=e.target.value
    if(key==="country") {
      setAddPackage({...addPackage,country:newValue})
    }
    if(key==="typeOfErp") {
      setAddPackage({...addPackage,typeOfErp:newValue})
    }
    if(key==="distributionPrice"){
      setAddPackage({...addPackage,distributionPrice:e.target.value})
    }
    if(key==="packageDuration"){
      setAddPackage({...addPackage,packageDuration:newValue})
    }
    if(key==="backupPrice"){
      setAddPackage({...addPackage,backupPrice:e.target.value})
    }
    if(key==="module"){
      setAddPackage({...addPackage,module:newValue})
    }
    if(key==="subModule"){
      setAddPackage({...addPackage,subModule:newValue})
    }
    if(key==="addingLocationPrice"){
      setAddPackage({...addPackage,addingLocationPrice:e.target.value})
    }
    if(key==="sellingPrice"){
      setAddPackage({...addPackage,sellingPrice:e.target.value})
    }
    if(key==="durationType"){
      setAddPackage({...addPackage,durationType:e.target.value})
    }
    if(key==="discription"){
      setAddPackage({...addPackage,discription:e.target.value})
    }
    if(key==="tax"){
      setAddPackage({...addPackage,tax:e.target.value})
    }
    if(key==="cess"){
      setAddPackage({...addPackage,cess:e.target.value})
    }
    if(key==="planType"){
      setAddPackage({...addPackage,planType:newValue})
    }
    if(key==="planPrice"){
      setAddPackage({...addPackage,planPrice:e.target.value})
    }
    if(key==="isMobilePackage"){
      setAddPackage({...addPackage,isMobilePackage:e.target.checked})
    }
    if(key==="mobilepackagePrice"){
      setAddPackage({...addPackage,mobilepackagePrice:e.target.value})
    }
    if(key==="mobileLocPrice"){
      setAddPackage({...addPackage,mobileLocPrice:e.target.value})
    }
    if(key==="color"){
      setAddPackage({...addPackage,color:e.target.value})
    }
    if(key==="durationType"){
      setAddPackage({...addPackage,durationType:e.target.value})
    }
    if (key === "tax") {
       const selectedTaxId =newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
       const newFilteredTaxOptions =selectedTaxId !== undefined? TaxList.filter((option) => option.includedPrice === selectedTaxId)
        : TaxList;
        setUpdatedTaxArray(newFilteredTaxOptions)
        setAddPackage({...addPackage,tax:newValue})
       
    }

}

const handlePaste = (event) => {
  const pastedText = event.clipboardData.getData('text');
  // Assuming the pasted text is a valid color code
  // You may want to perform validation here
  setAddPackage({ ...addPackage, color: pastedText });
  event.preventDefault();
};

// useEffect(()=>{
//   setAddPackage({...addPackage,sellingPrice:
//     (addPackage?.planPrice === "" ? 0 : parseFloat(addPackage?.planPrice))+(addPackage?.addingLocationPrice === "" ? 0 : parseFloat(addPackage?.addingLocationPrice))

//   })
// },[addPackage?.planPrice,addPackage?.addingLocationPrice])

const getImage=(e)=>{
  setImage(e.target.files[0])
  setImageName(e.target.files[0]?.name)
}

const addNewPlan=()=>{
  addPlanAPICall({name:planType})
}




// add button function
const addButtonFn=()=>{

 

    // setModuleArray([...moduleArray,
    //   {
    //     moduleId:addPackage?.module?._id,
    //     moduleName:addPackage?.module?.name,
    //     submodule:addPackage?.subModule
       
    //   }
    //   ])


    // first
    const newModuleId = addPackage?.module?._id;
    const newSubModule = addPackage?.subModule; 
    const moduleName=addPackage?.module?.name;

    // Check if the moduleId already exists in the data
    const existingData = moduleArray.find((item) => item.moduleId === newModuleId);
   
    if (existingData) {
      console.log(existingData);
      // If moduleId exists, add the new submodule to the subModule array
      if(existingData?.submodule?.some(obj=>obj?._id===newSubModule?._id)){
        alert("sub module already exist")
      }
      else{
        setModuleArray((prevData) =>
        prevData.map((item) => {
          if (item.moduleId === newModuleId) {
            return {
            
              ...item,
              submodule: [...item.submodule, newSubModule ]
            };
          }
          console.log(item);
          return item;
          
        })
      );
      }
      
    } else {
      // If moduleId doesn't exist, create a new object with the submodule array
      setModuleArray((prevData) => [
        ...prevData,
        {
          moduleId: newModuleId,
          submodule: [newSubModule],
          moduleName:moduleName
        }
      ]);
    }
    setAddPackage({...addPackage,
        subModule:null
        })
}







const clearState=()=>{
  setAddPackage(addPackageForm)
  setModuleArray([])
  setContentToBackend([])
  setSubModuleArray([])
  setImageName('')
  setImage()
  // setTransactionId("")
}



const handleSubModuleDataUpdate = (data) => {
  // const transformedObject = {};
  // data.forEach(item => {
  //     transformedObject[item.name] = item.isChecked;
  // });
  // setSubModuleArray(transformedObject);
  setSubModuleArray(data)
};

console.log(subModuleArray);

const handleContentUpdate=(data)=>{
  setContentToBackend(data)
}
console.log(contentToBackend);

const bodyToPackageAndPlans={
  packageName:addPackage?.typeOfErp?.name,
  erpType:addPackage?.typeOfErp?.name,
  country:addPackage?.country?.name,
  packageDuration:addPackage?.packageDuration?.duration,
  // durationType:addPackage?.durationType?.type,
  distributionPrice:Number(addPackage?.distributionPrice),
  backupPrice:Number(addPackage?.backupPrice),
  module:{
    basic:subModuleArray[0]?.isChecked,
    accounts:subModuleArray[1]?.isChecked,
    payroll:subModuleArray[2]?.isChecked,
    mobileService:subModuleArray[3]?.isChecked
  },
  sellingPrice:Number(addPackage?.sellingPrice),
  locationPrice:Number(addPackage?.addingLocationPrice),
  description:addPackage?.discription,
  planType:addPackage?.planType?._id,
  // vatorgst:addPackage?.tax,
  // cess:addPackage?.cess
  isMobilePackage:addPackage?.isMobilePackage,
  planPrice:addPackage?.planPrice,
  mobilepackagePrice:Number(addPackage?.mobilepackagePrice),
  mobileLocPrice:Number(addPackage?.mobileLocPrice)

}
const formData=new FormData()
formData.append("packageName",addPackage?.typeOfErp?.name)
formData.append("country",addPackage?.country?.name)
formData.append("packageDuration",addPackage?.durationType)
formData.append("durationType",addPackage?.packageDuration?.name)
formData.append("distributionPrice",Number(addPackage?.distributionPrice))
formData.append("backupPrice",Number(addPackage?.backupPrice))
formData.append("module",JSON.stringify(
  //subModuleArray
  {
  basic:subModuleArray[0]?.isChecked,
  accounts:subModuleArray[1]?.isChecked,
  payroll:subModuleArray[2]?.isChecked,
  mobileService:subModuleArray[3]?.isChecked
}
))
formData.append("sellingPrice",Number(addPackage?.sellingPrice))
formData.append("locationPrice",Number(addPackage?.addingLocationPrice))
// formData.append("description")
formData.append("planType",addPackage?.planType?._id)
formData.append("erpType",addPackage?.typeOfErp?.name)
formData.append("isMobilePackage",addPackage?.isMobilePackage)
// formData.append("planPrice",addPackage?.planPrice)
formData.append("planPrice",0)
formData.append("mobilepackagePrice",Number(addPackage?.mobilepackagePrice))
formData.append("mobileLocPrice",Number(addPackage?.mobileLocPrice))
formData.append("additionalInfo",JSON.stringify(contentToBackend))
{image !== undefined && formData.append("file",image)}

formData.append("hexCode",addPackage?.color)
formData.append("description",addPackage?.discription)
addPackage?.tax?.length > 0 && addPackage?.tax?.map((obj)=>{
  formData.append("taxArray",obj?._id)
}) 
formData.append("released",addPackage?.released)

const packageSubmitBtnFn=()=>{
  // for (const pair of formData.entries()) {
  //     console.log(pair[0] + ': ' + pair[1]);
  //     }


addPackageAPICall(formData,clearState,updateListner,setSnackBarStates,snackBarStates)
}

const deletePackageFn=(id)=>{
  deletePackageAPICall(id,updateListner,setSnackBarStates,snackBarStates)
}

const editPackageFunction=(id)=>{
  singlePackageAPICall({packageId:id})
  setEditedId(id)
  setImage()
}

const editSubmitFunction=()=>{
   formData.append("_id",editedId)
   editPackageAPICall(formData,clearState,updateListner,setSnackBarStates,snackBarStates)
}

useEffect(()=>{
   if(singlePackage !== undefined){
    setAddPackage({...addPackage,
     country:filterObjFromList("name",CountryListWithCode,"country",singlePackage),
     typeOfErp:filterObjFromList("name",ErpTypes,"erpType",singlePackage),
     distributionPrice:singlePackage?.distributionPrice,
     packageDuration:filterObjFromList("name",durationType,"durationType",singlePackage),
     durationType:singlePackage?.packageDuration,
     backupPrice:singlePackage?.backupPrice,
     planType:filterObjFromList("_id",planList,"planType",singlePackage),
     planPrice:singlePackage?.planPrice,
     sellingPrice:singlePackage?.sellingPrice,
     mobilepackagePrice:singlePackage?.mobilepackagePrice,
     mobileLocPrice:singlePackage?.mobileLocPrice,
     discription:singlePackage?.description,
     color:singlePackage?.hexCode,
     released:singlePackage?.released,
     tax:updatedTaxArray.filter(item => singlePackage?.taxArray.includes(item._id)),
     addingLocationPrice:singlePackage?.locationPrice
    // tax:singlePackage?.taxArray
    
    })
    // const jsonString = 'singlePackage?.module';
    // const parsedObject = JSON.parse(singlePackage?.module);
  let data = Object.keys(singlePackage?.module).map(key => ({
      name: key,
      isChecked: singlePackage?.module[key]
  }));
    setSubModuleArray(data)
    setContentToBackend(singlePackage?.additionalInfo)
    // setImage(`data:name;base64,${singlePackage?.logo?.data}`)
    // setImage(singlePackage?.logo?.data)
    setImageName(singlePackage?.logo?.name)
    setTransactionId(singlePackage?.packageId)
    
  }
},[singlePackage])




console.log(addPackage);








  return (
    <>
      <TopContainer label="Package & Plans" />
      <div className="global-container package-plans-parent">
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
           <h3>Add Package & Plans</h3>
                  <div style={{display:"flex",margin:"0 20px",alignItems:"center"}}>
                      <input
                           className="toggle-checkbox"
                           type={"checkbox"}
                           checked={addPackage?.released}
                           onChange={(e)=>setAddPackage({...addPackage,released:e.target.checked})}
                           name="released"
                       />
                      <label htmlFor="" style={{fontSize:"0.8rem",marginLeft:"4px"}}>Released</label>
                  </div>
                     
           <div>
             <input 
               type="checkbox"  
               style={{accentColor:"red",width:"20px",cursor:"pointer"}}
              checked={addPackage?.isMobilePackage}
              onChange={getAddPackage('isMobilePackage')}
              />
             <label htmlFor="" style={{fontSize:"0.8rem",color:"red",fontWeight:600}}>Mobile Package</label>
           </div>
        </div>
       
        <hr />
        <div className="add-package-form">
          <div className="global-single-input" style={{width:"30%"}}>
            <p>Transaction ID</p>
            <input
              disabled
              value={transactionId}
            />
          </div>
          <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Country</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={CountryListWithCode||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params}/>}
              value={addPackage?.country}
              onChange={getAddPackage('country')}
            />
          </div>
          <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Type of ERP</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={ErpTypes||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params}/>}
              value={addPackage?.typeOfErp}
              onChange={getAddPackage('typeOfErp')}
            />
          </div>
          <div className="global-single-input" style={{width:"30%"}}>

            <p>Distribution Price</p>
            <input type="text"
              value={addPackage?.distributionPrice}
              onChange={getAddPackage('distributionPrice')}
            />
            

          </div>
          <div
            className="global-single-input auto-complete"
            style={{ position: "relative",width:"30%" }}
          >
            <p>Duration</p>
            <div style={{display:"flex"}}>
            <Autocomplete
              sx={{width:"100%"}}
              options={durationType||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params}/>}
              value={addPackage?.packageDuration}
              onChange={getAddPackage('packageDuration')}
              disabled
            />
            <input 
              type="text" 
              style={{width:"50%",marginLeft:"4px"}}
              value={addPackage?.durationType}
              onChange={getAddPackage('durationType')}
              />
            </div>
           
            {/* <div className="package-module-add-btn">
              <button onClick={clickPackageDuration}>
                Add Duration
              </button>
            </div> */}
          </div>
          {/* <div
            className="global-single-input auto-complete"
            style={{ position: "relative",width:"30%" }}
          >
            <p>Duration Type</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={[{type:"Year"}]||[""]}
              getOptionLabel={(option)=>option?.type}
              renderInput={(params)=><TextField {...params}/>}
              value={addPackage?.durationType}
              onChange={getAddPackage('durationType')}
            />
           
          </div> */}
          <div className="global-single-input" style={{width:"30%"}}>
            <p>Backup Price</p>
            <input type="text"
              value={addPackage?.backupPrice}
              onChange={getAddPackage('backupPrice')}
             />

          </div>
          <div
            className="global-single-input auto-complete"
            style={{ position: "relative",width:"30%" }}
          >
            <p>Types Of Plans</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={planList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params}/>}
               value={addPackage?.planType}
               onChange={getAddPackage('planType')}
            />
            <div className="package-module-add-btn">
              <button onClick={()=>setAddPlan(true)}>
                Add Plan
              </button>
            </div>
          </div>
          <div
            className="global-single-input auto-complete"
            style={{ position: "relative",width:"30%" }}
          >
            <p>Select Sub Modules</p>
            {/* <Autocomplete
              sx={{width:"100%"}}
              options={[""]}
              getOptionLabel={(option)=>option}
              renderInput={(params)=><TextField {...params}/>}
             
            /> */}
            <input 
            type="text" 
            onClick={()=>setAddSubModule(true)} placeholder="Select module"/>
           
          </div>
          <div
            className="global-single-input auto-complete"
            style={{ position: "relative",width:"30%" }}
          >
            <p>Select Content</p>
              <input 
                type="text"  placeholder="Add Content"
                onClick={()=>setContentPopup(true)}
                />
            {/* <Autocomplete
              sx={{width:"100%"}}
              options={[""]}
              getOptionLabel={(option)=>option}
              renderInput={(params)=><TextField {...params}/>}
            /> */}
            {/* <div className="package-module-add-btn">
              <button>
                Add Content
              </button>
            </div> */}
          </div>
         
         
          <div className="global-single-input" style={{width:"30%"}}>
            <p>Adding Location Price</p>
            <input type="text"
            value={addPackage?.addingLocationPrice}
            onChange={getAddPackage('addingLocationPrice')}
            />
          </div>
          {/* <div className="global-single-input" style={{width:"30%"}}>
            <p>Plan Price</p>
            <input type="text"
               value={addPackage?.planPrice}
               onChange={getAddPackage('planPrice')}
            />
          </div> */}
          <div className="global-single-input" style={{width:"30%"}}>
            <p>Plan Price</p>
            <input type="text"
            value={addPackage?.sellingPrice}
            onChange={getAddPackage('sellingPrice')}
            />
          </div>
          <div className="global-single-input" style={{width:"30%"}}>
            <p>Package Saved %</p>
            <input type="text"
            />
          </div>
       
          <div  style={{width:"30%",display:"flex",justifyContent:"space-between",margin:"1%"}}>
            <div className="global-single-input" style={{width:"48%"}}>
               <p>Mobile Package Price</p>
               <input 
                 type="text"  
                 style={{width:"90%"}}
                 value={addPackage?.mobilepackagePrice}
               onChange={getAddPackage('mobilepackagePrice')}
                 />
            </div>
            <div className="global-single-input" style={{width:"48%"}}>
               <p>Location Price</p>
               <input 
                 type="text" 
                 style={{width:"90%"}}
                 value={addPackage?.mobileLocPrice}
                 onChange={getAddPackage('mobileLocPrice')}
                 />
            </div>
           
          </div>
          <div  style={{width:"30%",display:"flex",justifyContent:"space-between",margin:"1%"}}>
            <div className="global-single-input" style={{width:"58%"}}>
               <p>Upload Image</p>
               <div className="upload-image-input">
                  <input 
                    type="text"  
                     style={{width:"90%"}}
                     value={imageName}
                 />
                 <input type="file" id="card-logo" onChange={getImage}/>
                   <Tooltip title="Upload Image">
                   <label htmlFor="card-logo">
                    Upload&nbsp;&nbsp;&nbsp;<img src={uploadIcon} alt="uploadIcon" />
                   </label> 
                 </Tooltip>
               </div>
             
            </div>
            <div className="global-single-input" style={{width:"38%"}}>
               <p>Add Colour</p>
               <div className="add-color-container">
                    <input 
                      type="text" 
                      value={addPackage?.color} 
                      style={{width:"87%"}}
                      onPaste={handlePaste}
                      onChange={getAddPackage('color')}
                      />
                    <input 
                        type="color" 
                        value={addPackage.color}
                        onChange={getAddPackage('color')}

                 />
               </div>
             
            </div>
          
           
          </div>
          <div className="global-single-input" style={{width:"30%"}}>
               <p>Description</p>
               <textarea 
                  type="text"
                  name="" id="" cols="30" rows="4"
                  value={addPackage?.discription}
                  onChange={getAddPackage('discription')}
                  ></textarea>
            </div>
            <div
            className="global-single-input auto-complete"
            style={{ position: "relative",width:"30%" }}
          >
            <p>Tax</p>
            <Autocomplete
              multiple
              sx={{width:"100%"}}
              options={updatedTaxArray||[""]}
              getOptionLabel={(option)=>option?.taxName}
              renderInput={(params)=><TextField {...params}/>}
              value={addPackage?.tax}
             onChange={getAddPackage('tax')}
            />
           
          </div>
        
        

         
        
         
        </div>


       
        <div className="package-plans-cancel-submit-btn-container">
          <button className="global-button-contained-black-outlined">
            Cancel
          </button>
          <button className="global-button-contained-blue" 
            onClick={singlePackage === undefined ? packageSubmitBtnFn:editSubmitFunction}
          >{singlePackage === undefined ?"Submit":"Update"}</button>
        </div>
      </div>
      <div className="global-container">
          {/* <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Select Country</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={CountryListWithCode||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} placeholder="Select Country"/>}
              value={country}
              onChange={(e,newValue)=>setCountry(newValue)}
            />
          </div> */}
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Country</th>
                <th>Type of ERP</th>
                <th>Plan Type</th>
                <th>Distribution Price</th>
                <th>Selling Price</th>
                <th>Trial Period(Days)</th>
                <th>Location Price</th>
                <th>Backup Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                packageList?.map((r,i)=>(
                  <tr>
                  <td>{r?.packageId}</td>
                  <td>{r?.country}</td>
                  <td>{r?.packageName}</td>
                  <td>{r?.plans?.name}</td>
                  <td>{r?.distributionPrice}</td>
                  <td>{r?.sellingPrice}</td>
                  <td>{r?.packageDuration}</td>
                  <td>{r?.locationPrice}</td>
                  <td>{r?.backupPrice}</td>
                  <td>
                    {/* <IconButton >
                      <VisibilityOutlined sx={{ color: "#83a62f" }} />
                    </IconButton> */}
                    <IconButton onClick={()=>editPackageFunction(r?._id)}>
                      <EditOutlinedIcon sx={{color:"blue"}} />
                    </IconButton>
                    <IconButton onClick={()=>deletePackageFn(r?._id)}>
                      <DeleteOutlineOutlinedIcon sx={{ color: "#fd0301" }} />
                    </IconButton>
                  </td>
                </tr>
                ))
              }
             
            </tbody>
          </table>
        </div>
      </div>
     
      <PackageDuration   open={openPackage}   onClose={closeConfirmDialog}   packageDuration={packageDurationList} update={updateListner} />
     
    
         {/* add plan */}
        <Dialog maxWidth="lg"  open={addPlan}>
            <div className="package-parent">
               <div className="package-head-container">
                  <h2>Select Plan</h2>
               </div>
               <div className="package-body-container">
               <div className="package-body-container-left" >
                  <div className="global-single-input auto-complete" style={{width:'100%'}}>
                    <p>Plans</p>
                    {/* <Autocomplete
                       sx={{width:"100%"}}
                       options={[""]}
                       getOptionLabel={(option)=>option}
                       renderInput={(params)=><TextField {...params}/>}
             
                      /> */}
  
                      <input type="text" value={planType} onChange={(e)=>setplanType(e.target.value)}  />
                   </div>
             
              </div>
            <div className="package-body-container-right">
                    <button onClick={addNewPlan}>Add</button>
            </div>
               </div>
               <div className="package-container-table">
           <table>
                  <thead>
                      <tr>
                          <th>plan</th>
                          <th>Action</th>
                          </tr>
                  </thead>
                  <tbody>
                      {
                        planList?.map((r)=>(
                          <tr>
                          <td>{r?.name}</td>
                          <td>
                            <IconButton 
                         
                       
                            >
                               <DeleteOutlineOutlinedIcon sx={{ color: "#fd0301" }}/>
                            </IconButton></td>
                         </tr>
                        ))
                      }
                      
                    
                    
                     
                  </tbody>
              </table>
           </div>
           <div className="package-container-button">
                    <div className="package-container-button-ctn">
                        <button onClick={()=>setAddPlan(false)} className="cancel-btn">Cancel</button>
                    </div>
                    <div className="package-container-button-ctn">
                    <button className="submit-btn">Submit</button>
                    </div>
           </div>
            </div>
        </Dialog>

     
        <AddSubModule open={addSubModule} onClose={()=>setAddSubModule(false)} onDataUpdate={handleSubModuleDataUpdate} dataArray={subModuleArray}/>
        <ContentPopup  open={contentPopup}  onClose={()=>setContentPopup(false)} onDataUpdate={handleContentUpdate} data={contentToBackend}/>
        <SuccessSnackbar
            open={snackBarStates.success}
            handleClose={handleCloseSuccess()}
            message={snackBarStates.snackMsg}
         />
        <ErrorSnackbar
         open={snackBarStates.error}
          handleClose={handleCloseSuccess()}
         message={snackBarStates.snackMsg}
        />
     </>
  );
};

export default PackageAndPlans;
