import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentTime, today } from '../../../Functions/Date'
import { Autocomplete, TextField } from '@mui/material'
import { viewAllPackageList } from '../../../API/AddPackage/AddPackageAPI'
import "../../Pages/Registered Users/profileinfomycart.css"
import { viewAllSmsPackageAPICall, viewAllWhatsappPackageAPICall } from '../../../API/Add_sms_Package/smsPackage'
import { LoadingForm } from '../../Custom Components/LoadingForm'
import SuccessSnackbar from '../../SingleCompoents/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../SingleCompoents/SnackBars/ErrorSnackbar'
import { addOutletAPICall, freeTrialAPICall, puchaseManualAPICall, viewPlansSingleAPICall } from './CustomerRegistrationAPICall'
import { get_my_plans_singleView } from '../../../Redux/RegisterdList/registerdUserSlice'
import { store } from '../../../Redux/Store'
import emptyCart from "../../../Assets/Images/emptycartImage.png"
import { getSingleCustomer } from '../../../API/RegisterdUsers/RegisterdUsersApi'

export const CustomerRegistartionCart = () => {
  const packageList=useSelector((state)=>state.generatePackageIdSlice?.value1)
  const smsPackage=useSelector((state)=>state.getAllsmsPackageSlice.value)
  const whatsappPackage=useSelector((state)=>state.getAllsmsPackageSlice.whatsapp)
  const popupData=useSelector((state)=>state.customerRegistrationSlice.initialForm)
  const getSingleCustomers = useSelector((state)=>state.registerdUsers.singleUserData)
  
  const [cartFullList,setCartFullList]=useState([])
  const [checkedDataList,setCheckedDataList]=useState([])
  const singlePckageId=localStorage.getItem("packageId")
  const customerId=localStorage.getItem("singleViewId")
  const singlePackageData = useSelector((state) => {
    return state.registerdUsers.singleData;
  });
  const amountStateInitial={
    subTotal:0,
    tax:0,
    grandToatl:0,
    totalPaid:0,
    balance:0,
    totalOutlet:0
 }
 const [amountState,setAmountState]=useState(amountStateInitial)
 const [loadingForm,setLoadingForm]=useState(false)
const [snackBarStates, setSnackBarStates] = useState({
  success: false,
  error: false,
  message: "",
  alert: false,
});
const closeSnackbar = () => {
  setSnackBarStates({
    ...snackBarStates,
    success: false,
    error: false,
    alert: false,
    message: "",
  });
};
const checkboxCheckFn = (index, key) => {
    const updatedList = [...cartFullList];
      updatedList[index] = {...updatedList[index],[key]: !updatedList[index][key]};
      setCartFullList(updatedList);
};
const smsPackageChange = (index) => (e, newValue) => {
  const updatedList = [...cartFullList];
  updatedList[index].smsPackage = newValue;
  updatedList[index].smsPackageStartDate = newValue?.STARTDATE;
  updatedList[index].smsPackageEndDate = newValue?.ENDDATE;
  setCartFullList(updatedList);
};
 const whatsappPackageChange=(index)=>(e,newValue)=>{
   const updatedList = [...cartFullList];
  updatedList[index].whatsappPackage = newValue;
  updatedList[index].whatsappPackageStartDate = newValue?.STARTDATE;
  updatedList[index].whatsappPackageEndDate = newValue?.ENDDATE;
  setCartFullList(updatedList);
 }


   const incButtonFn = (index) => {

     
     const updatedPackageListCart = [...checkedDataList];
     updatedPackageListCart[index].outletCount++;
     updatedPackageListCart[index].outletAmount = updatedPackageListCart[index].outletCount * updatedPackageListCart[index].locationPrice;
   //   updatedPackageListCart[index].orderTotal=
   //   (updatedPackageListCart[index].outletAmount)+
   //   (updatedPackageListCart[index].sellingPrice)+
   //   (updatedPackageListCart[index].enableSmsPackage && updatedPackageListCart[index].smsPackage?.sellingtotal)+
   //   (updatedPackageListCart[index].enableBackupPrice && updatedPackageListCart[index].backupPrice)
     setCheckedDataList(updatedPackageListCart);
   }
   const decButtonFn=(index)=>{
     const updatedPackageListCart = [...checkedDataList];
     updatedPackageListCart[index].outletCount--;
     updatedPackageListCart[index].outletAmount = updatedPackageListCart[index].outletCount * updatedPackageListCart[index].locationPrice;
     setCheckedDataList(updatedPackageListCart);
   }

   const inputChangeFn=(index)=>(e)=>{
      const { value } = e.target;
      console.log(value);
        let updatedList = [...checkedDataList];
        updatedList[index].payAmount = value;
        setCheckedDataList(updatedList);
   }


   const calculateTotalAmount = (checkedDataList) => {
    console.log(checkedDataList);
    const calculatedValues = checkedDataList?.map((item) => {
      if(item?.inclusive===true){

        let totalAmount = (item?.sellingPrice ? parseFloat(item?.sellingPrice):0) +
        parseFloat(item?.outletAmount) +
        (item?.enableBackupPrice === true && parseFloat(item?.backupPrice) || 0) +
        (item?.enableSmsPackage === true && parseFloat(item?.smsPackage?.sellingtotal) || 0) +
        (item?.enableWhatsappPackage === true && parseFloat(item?.whatsappPackage?.sellingtotal) || 0);
        let totalOutlet = item?.outletAmount;
        let taxArray = item?.taxDetails?.map((obj) => obj?.amount);

        let totalOfTax = taxArray.reduce((sum, item) => sum + item, 0);
        let taxableAmount = (totalAmount / (totalOfTax + 100)) * 100;
        let incTax =  parseFloat(totalAmount) - parseFloat(taxableAmount)


       return { ...item, totalAmount: totalAmount, totalOutlet: totalOutlet,inclusiveTax:incTax };

      }
      else{
        let totalAmount = (item?.sellingPrice ? parseFloat(item?.sellingPrice):0) +
        parseFloat(item?.outletAmount) +
        (item?.enableBackupPrice === true && parseFloat(item?.backupPrice) || 0) +
        (item?.enableSmsPackage === true && parseFloat(item?.smsPackage?.sellingtotal) || 0) +
        (item?.enableWhatsappPackage === true && parseFloat(item?.whatsappPackage?.sellingtotal) || 0);
        let totalOutlet = item?.outletAmount;

        let totalTax = item?.taxDetails?.reduce((sum, item) => sum + item?.amount, 0);

        let totalExclTax = (totalAmount * totalTax) / 100;


       return { ...item, totalAmount: totalAmount, totalOutlet: totalOutlet,exclusiveTax:totalExclTax };
      }
    
    });
  
    // If you want to update the state, uncomment the line below
    // console.log(calculatedValues);
    const areEqual = JSON.stringify(calculatedValues) === JSON.stringify(checkedDataList);
    if (!areEqual) {
      setCheckedDataList(calculatedValues);
    }
    //  setCheckedDataList(calculatedValues);
  };
  const calculateFullTotal=(checkedDataList)=>{
    let totalAmount=0
    let totalPaid=0
    let totalInclusive=0
    let totalExclusive=0
    let totalOutlet =0
    checkedDataList?.forEach((item)=>{
          totalAmount +=item?.totalAmount
          totalPaid += !item?.payAmount ? 0 : parseFloat(item?.payAmount)
          totalInclusive +=parseFloat(item?.inclusiveTax)
          totalExclusive += parseFloat(item?.exclusiveTax)
          totalOutlet +=parseFloat(item?.totalOutlet)
    })
    console.log(totalExclusive);
    setAmountState({
            ...amountState,
             subTotal: parseFloat(totalAmount)+(totalExclusive ? parseFloat(totalExclusive):0),
             tax:(totalInclusive ? parseFloat(totalInclusive):0)+(totalExclusive ? parseFloat(totalExclusive):0),
            // grandTotal: totalAmount + totalTaxIncluded + totalTaxExcluded,
             totalOutlet: totalOutlet,
             totalPaid: totalPaid,
             balance: (parseFloat(totalAmount)+(totalExclusive ? parseFloat(totalExclusive):0)) - totalPaid
           });

  }
  console.log(getSingleCustomers);


  
  function calculateEndDate(startDate, packageDuration, durationType) {
    const endDate = new Date(startDate);
    switch (durationType) {
        case 'Year':
            endDate.setFullYear(endDate.getFullYear() + packageDuration);
            break;
        case 'month':
            endDate.setMonth(endDate.getMonth() + packageDuration);
            break;
        case 'day':
            endDate.setDate(endDate.getDate() + packageDuration);
            break;
        // Add more cases for other duration types if needed
        default:
            console.error('Invalid duration type:', durationType);
            return null;
    }
    const formatDtae =`${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`
    return formatDtae;
 }

  //  function calculateEndDate(fromDate, packageDuration, durationType) {
   
  //     const [dateString, timeString] = fromDate.split(' ');
  //     const [year, month, day] = dateString.split('-').map(Number);
  //     const [hour, minute, second] = timeString.split(':').map(Number);
    
    
  //     const fromDateObject = new Date(year, month - 1, day, hour, minute, second);
    
      
  //     let endDate = new Date(fromDateObject);
  //     switch (durationType?.toLowerCase()) {
  //       case "year":
  //       case "years":
  //         endDate.setFullYear(endDate.getFullYear() + packageDuration);
  //         break;
  //       case "month":
  //       case "months":
  //         endDate.setMonth(endDate.getMonth() + packageDuration);
  //         break;
     
  //       default:
  //         console.error("Unsupported duration type:", durationType);
  //         return null;
  //     }
    
    
  //     const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')} ${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}:${endDate.getSeconds().toString().padStart(2, '0')}`;
  //     return formattedEndDate;
  //   }

   const tableDataToBackend=checkedDataList?.map((item)=>(
      {
        packageId:item?._id,
        module:item?.module,
        outletNo:item?.outletCount,
        outletAmount:item?.outletAmount,
        backupPrice:item?.enableBackupPrice===true?item?.backupPrice:0,
        totalAmount:item?.totalAmount,
        fromDate:`${today} 12:00:00`,
        toDate:`${calculateEndDate(today,item?.packageDuration,item?.durationType)} 12:00:00`,
        paidAmount:Number(item?.payAmount),
        sellingPrice:item?.sellingPrice,
        smspackageid:item?.enableSmsPackage === true ? item?.smsPackage?._id : "",
        smspackageprice:item?.enableSmsPackage === true ? parseFloat(item?.smsPackage?.sellingtotal) : 0,
        smspackageqty:item?.enableSmsPackage === true ? parseFloat(item?.smsPackage?.sellingQty) : 0,
        whatsappid:item?.enableWhatsappPackage === true ? item?.whatsappPackage?._id : "",
        whatsappPrice:item?.enableWhatsappPackage === true ? parseFloat(item?.whatsappPackage?.sellingtotal) : 0,
        whatsappQty:item?.enableWhatsappPackage === true ? parseFloat(item?.whatsappPackage?.sellingQty) : 0,
        taxInclusive:item?.inclusiveTax,
        taxexclusive:item?.exclusiveTax
      }
    ))

   // manula purchase in case of existing customer
const bodyToApiexistCustomer={
   cusId:popupData?.cusId,
   package:tableDataToBackend
 }
 //manual purchase in case of new customet
 const bodyToApiNewCustomer={
   mobile:popupData?.mobile,
   companyName:popupData?.companyName,
   email:popupData?.email,
   info:popupData?.info,
   username:popupData?.username,
   password:popupData?.password,
   package:tableDataToBackend
 }

 const bodyToAddOutlet={
  cusId:customerId,
  packageId:checkedDataList !== undefined && checkedDataList[0]?._id,
  planId: singlePackageData !== undefined  && getSingleCustomers?.packageList && getSingleCustomers?.packageList[0]?.planId,
  outletNo:checkedDataList !== undefined &&  checkedDataList[0]?.outletCount,
  outletAmount:checkedDataList !== undefined &&  checkedDataList[0]?.outletAmount,
  totalAmount:checkedDataList !== undefined &&  checkedDataList[0]?.totalAmount,
  fromDate: `${today} 12:00:00`,
  endDate:checkedDataList !== undefined && `${calculateEndDate(today,checkedDataList[0]?.packageDuration,checkedDataList[0]?.durationType)} 12:00:00`,
  isRenew: false,
}

console.log(singlePackageData?.packageList)


   const purchaseBtnClick=()=>{
    if(singlePckageId){
      addOutletAPICall(
        bodyToAddOutlet,
        setSnackBarStates,
        snackBarStates,
        setCheckedDataList
      )
    }
    else{

      if(popupData?.existCustomer){
        puchaseManualAPICall(
         bodyToApiexistCustomer,
         setLoadingForm,
         setSnackBarStates,
         snackBarStates,)
        }
  else{
     puchaseManualAPICall(bodyToApiNewCustomer,setLoadingForm,setSnackBarStates,snackBarStates)

   }

    }
     
      
   }

   const ClearBtnFn=()=>{
    localStorage.removeItem("packageId")
    store.dispatch(get_my_plans_singleView({singleViewData:undefined}))
    setCheckedDataList([])
   }

   const tableDataToBackendRenew=checkedDataList?.map((item)=>(
    {
      packageId:item?._id,
      module:item?.module,
      outletNo:item?.outletCount,
      outletAmount:item?.outletAmount,
      backupPrice:item?.enableBackupPrice===true?item?.backupPrice:0,
      totalAmount:item?.totalAmount,
      fromDate:`${today} 12:00:00`,
      // toDate:`${calculateEndDate(today,item?.packageDuration,item?.durationType)} 12:00:00`,
      paidAmount:Number(item?.payAmount),
      sellingPrice:item?.sellingPrice,
      smspackageid:item?.enableSmsPackage === true ? item?.smsPackage?._id : "",
      smspackageprice:item?.enableSmsPackage === true ? parseFloat(item?.smsPackage?.sellingtotal) : 0,
      smspackageqty:item?.enableSmsPackage === true ? parseFloat(item?.smsPackage?.sellingQty) : 0,
      whatsappid:item?.enableWhatsappPackage === true ? item?.whatsappPackage?._id : "",
      whatsappPrice:item?.enableWhatsappPackage === true ? parseFloat(item?.whatsappPackage?.sellingtotal) : 0,
      whatsappQty:item?.enableWhatsappPackage === true ? parseFloat(item?.whatsappPackage?.sellingQty) : 0,
      taxInclusive:item?.inclusiveTax,
      taxexclusive:item?.exclusiveTax
    }
  ))


   const bodyTofreeTrialCustomer={
    cusId:popupData?.cusId,
    package:tableDataToBackendRenew
   }
   const freeTrialBtnClick=()=>{
    freeTrialAPICall(bodyTofreeTrialCustomer,setLoadingForm,setSnackBarStates,snackBarStates)
   }

  useEffect(()=>{
    let fullList=packageList?.map((obj)=>{
       return{
          ...obj,
          isChecked:false,
          enableMobilePackage:false,
          enableSmsPackage:false,
          smsPackage:null,
          smsPackageStartDate:"",
          smsPackageEndDate:"",
          enableBackupPrice:false,
          // outletCount:1,
          // outletAmount:obj?.locationPrice,
          outletCount:obj?.plans?.name === "Enterprice" ? 1 : 0,
          outletAmount:obj?.plans?.name === "Enterprice" ? obj.locationPrice : 0,
          packageFrmDate:today,
          enableWhatsappPackage:false,
          whatsappPackage:null,
          whatsappPackageStartDate:"",
          whatsappPackageEndDate:"",
          // packageToDate:calculateEndDate(today,obj?.packageDuration,obj?.durationType)
          orderTotal:parseFloat(obj?.sellingPrice)+parseFloat(obj?.locationPrice),
          payAmount:0,
          totalAmount:0,
          inclusive:obj?.taxDetails !== undefined && obj?.taxDetails[0]?.includedPrice,
          inclusiveTax:0,
          exclusiveTax:0
       }
       

     })
     setCartFullList(fullList)
 },[packageList])

 useEffect(()=>{
  if(singlePackageData === undefined){
    const checkedItems = cartFullList?.filter(item => item.isChecked);
    setCheckedDataList(checkedItems)
  }




},[cartFullList])

useEffect(()=>{
    

    calculateTotalAmount(checkedDataList)
    calculateFullTotal(checkedDataList)
   // checkedDataList.forEach(item => {
   //    let updatedList = [...cartFullList];
   //    const itemIndex = updatedList.findIndex(i => i.id === item.id);
  
   //    if (item.enableWhatsappPackage && item.whatsappPackage) {
   //      updatedList[itemIndex].orderTotal += parseFloat(item.whatsappPackage.sellingtotal);
   //    }
  
   //    setCartFullList(updatedList);
   //  });
},[checkedDataList])

useEffect(()=>{
  if(singlePckageId){
    viewPlansSingleAPICall({packageId:singlePckageId})
   }
},[singlePckageId])


console.log(singlePackageData)
useEffect(() => {
  if (singlePackageData !== undefined) {
     setCheckedDataList([{
        packageName: singlePackageData?.packageName,
        planType: singlePackageData?.planType,
        durationType: singlePackageData?.durationType,
        outletCount: 1,
        locationPrice: singlePackageData?.locationPrice,
        outletAmount: singlePackageData?.locationPrice,
        _id:singlePackageData?._id,
        packageDuration:singlePackageData?.packageDuration
     }])

     
  
  }
}, [singlePackageData]);



console.log(singlePackageData);

 useEffect(()=>{
  viewAllPackageList()
  viewAllSmsPackageAPICall()
  viewAllWhatsappPackageAPICall()
    getSingleCustomer({_id:customerId},setLoadingForm)
  
 },[])

 useEffect(() => {
   const handleBeforeUnload = (event) => {
     if (popupData !== undefined) {
       event.preventDefault();
       event.returnValue = ''; // Some browsers require a return value for this event
       return 'Data may not be saved. Are you sure you want to leave?';
     }
   };
 
   window.addEventListener('beforeunload', handleBeforeUnload);
 
 
   return () => {
     window.removeEventListener('beforeunload', handleBeforeUnload);
   };
 }, [popupData]);

  return (
    <div className='cart-main-container'>
    <div className='cart-container'>
       <div className="cart-left-container">
        {
          singlePackageData ===undefined?
        
         <div className='cart-left-item-container'>
          {
             cartFullList?.map((r,i)=>(
                <>
               <div className='cart-left-item-single-container' style={{flexDirection:"column"}}>
                <div style={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
               <div className='cart-left-item-single-checkbox-container'>
                  <input 
                    type="checkbox"
                    checked={r?.isChecked}
                    onChange={()=>checkboxCheckFn(i,"isChecked")}
                    
                    />
               </div>
               <div className='cart-left-item-single-image-label-container'>
                   <div className='cart-left-item-single-image' style={{backgroundColor:r?.hexCode}}>
                      <img src={`data:name;base64,${r?.logo?.data}`} alt="" />
                   </div>
                   <div style={{marginLeft:"8%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                       <h4 style={{fontSize:'1rem',fontWeight:600,margin:0}}>{r?.packageName}</h4>
                       <p style={{fontSize:'0.7rem',fontWeight:600,margin:0}}>{r?.plans?.name}</p>
                       <p style={{fontSize:'0.7rem',fontWeight:600,margin:0}}>Price - {r?.sellingPrice}</p>
                   </div>
               </div>
               <div style={{display:"flex",flexDirection:"column"}}>
                 <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <input 
                     type="checkbox" 
                     style={{width:"18px",accentColor:"black"}}
                     checked={r?.enableMobilePackage}
                     onChange={()=>checkboxCheckFn(i,"enableMobilePackage")}
                     />
                    <label htmlFor="" className='enable-mobile-package-p' >Enable Mobile Package</label>
                 </div>
                 <div style={{display:"flex",justifyContent:"flex-end",marginTop:"8px"}}>
                    <p className='view-details-p'>View Details</p>
                 </div>
                  
               </div>
               <div 
                //  onClick={()=>removeItemFromCart(r?.id)}
               >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                     <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                  </svg>
               </div>
               </div>
               <div className='cart-left-item-bottom-container'>
                  <div className='cart-left-item-bottom-container-single'>
                     <div style={{display:"flex",alignItems:"center",margin:"4px 0"}}>
                        <input 
                           type="checkbox" 
                           style={{accentColor:"blue",cursor:"pointer",width:"16px",height:"16px"}}
                           checked={r?.enableSmsPackage}
                           onChange={()=>checkboxCheckFn(i,"enableSmsPackage")}
                           disabled={r?.smsPackage===null}
                           />
                        <label htmlFor="" style={{cursor:"pointer",fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 8px"}}>Enable SMS Package</label>
                     </div>
                     <div className="cart-input-sms auto-complete" style={{width:"98%"}}>
                              
                              <Autocomplete
                               options={smsPackage||[]}
                               getOptionLabel={(option)=>
                                 `${option?.sellingQty}(${option?.sellingRate}=${option?.sellingtotal})`
                              }
                               renderInput={(params)=>(
                                  <TextField {...params} />
                               )}
                               onChange={smsPackageChange(i)}                      
                              />
                              {
                               r?.smsPackage===null && 
                               <p
                               style={{
                               fontSize: "0.7rem",
                               fontWeight: 600,
                               color: "red",
                                }}>
                               Select SMS Package*
                             </p>
                              }
                             
                     </div>
                     <div style={{display:"flex",justifyContent:"space-between",margin:"4px 8px"}}>
                      {
                         r?.smsPackage !== null &&
                         <>
                         <div style={{fontSize:"0.8rem",display:"flex",fontSize:"0.7rem",fontWeight:600,color:"blue"}}>
                            <i class="bi bi-calendar-event"></i>
                            <p style={{marginLeft:"4px"}}>{r?.smsPackageStartDate}</p>
                         </div>
                          <div style={{fontSize:"0.8rem",display:"flex",fontSize:"0.7rem",fontWeight:600,color:"red"}}>
                          <i class="bi bi-calendar-event"></i>
                          <p style={{marginLeft:"4px"}}>{r?.smsPackageEndDate}</p>
                           </div>
                           </>

                      }
                     
                       
                      
                     </div>
                      
                  </div>
                  <div className='cart-left-item-bottom-container-single'>
                     <div style={{display:"flex",alignItems:"center",margin:"4px 0"}}>
                        <input 
                           type="checkbox" 
                           style={{accentColor:"blue",cursor:"pointer",width:"16px",height:"16px"}}
                           checked={r?.enableWhatsappPackage}
                           onChange={()=>checkboxCheckFn(i,"enableWhatsappPackage")}
                           disabled={r?.whatsappPackage===null}
                           />
                        <label htmlFor=""  style={{cursor:"pointer",fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 8px"}}>Enable Whatsapp Package</label>
                     </div>
                     <div className="cart-input-sms auto-complete" style={{width:"98%"}}>
                              
                              <Autocomplete
                               options={whatsappPackage||[]}
                               getOptionLabel={(option)=> `${option?.sellingQty}(${option?.sellingRate}=${option?.sellingtotal})`}
                               renderInput={(params)=>(
                                  <TextField {...params} />
                               )}
                               onChange={whatsappPackageChange(i)}  
                                                 
                              />
                            
                             
                     </div>
                     {
                        r?.whatsappPackage===null &&  
                        <p
                        style={{
                        fontSize: "0.7rem",
                        fontWeight: 600,
                        color: "red",
                         }}>
                        Select Whatsapp Package*
                      </p>
                     }
                    
                     <div>
                        {/* <p>{r?.packageFrmDate}</p>
                        <p>{r?.packageToDate}</p> */}
                     </div>
                      
                  </div>
                  <div className='cart-left-item-bottom-container-single'>
                     <div style={{display:"flex",alignItems:"center",margin:"4px 0"}}>
                        <input 
                           type="checkbox" 
                           style={{accentColor:"blue",cursor:"pointer",width:"16px",height:"16px"}}
                           checked={r?.enableBackupPrice}
                           onChange={()=>checkboxCheckFn(i,"enableBackupPrice")}
                           />
                        <label htmlFor="" style={{cursor:"pointer",fontSize:"0.8rem",marginLeft:"8px",fontWeight:600}}>Backup Price</label>
                         <i class="bi bi-info-circle" style={{color:"red",marginLeft:"8px"}}></i>
                         <p style={{margin:0}}>- {r?.backupPrice}</p>
                     </div>
                       
                  </div>
               </div>
               </div>
              
               </>
             ))
          }
            
            
         </div>
         :
         <div className='cart-left-item-container'></div>
        }
      
       </div>
       <div className="cart-right-container">
         <div className='cart-right-top-container'>
          {
            checkedDataList?.length !== 0 && checkedDataList !== undefined ? checkedDataList?.map((r,i)=>(
                <div className='cart-right-top-single-container'>
                <div className='cart-right-top-single-one-container'>
                    <div className='cart-left-item-single-image' style={{width:"40px",height:"40px",backgroundColor:r?.hexCode}}>
                       <img src={`data:name;base64,${r?.logo?.data}`} alt="" />
                    </div>
                  <div style={{marginLeft:"6%"}}>
                   <p style={{fontSize:"1.1rem",fontWeight:600}}>{r?.packageName}</p>
                   <p style={{backgroundColor:"rgb(223 238 223)",fontSize:"0.7rem",fontWeight:600,padding:"1%"}}>{r?.plans?.name} <span style={{fontSize:"0.7rem",fontWeight:600,color:"green"}}>/{r?.durationType}</span></p>
                  </div>
                  <p style={{margin:"2% 2% 0 auto",fontSize:"0.75rem",fontWeight:700}}>{r?.sellingPrice}</p>
                </div>
                <div className='cart-right-top-single-one-container'>
                   <p style={{margin:"2% 2% 0 15%",fontSize:"0.75rem",fontWeight:600}}>Outlet Amount</p>
                   <div style={{display:"flex",width:"25%",justifyContent:"space-evenly",alignItems:"center"}}>
                      <button disabled={r?.outletCount===0}  onClick={()=>decButtonFn(i)} className='cart-plus-btn'>-</button>
                      <p style={{color:"blue",fontSize:"0.8rem"}}>{r?.outletCount}</p>
                      <button onClick={()=>incButtonFn(i)} className='cart-plus-btn'>+</button>
                   </div>
                   <p style={{margin:"2% 2% 0 auto",fontSize:"0.75rem",fontWeight:700}}>{r?.outletAmount}</p>
                </div>
                <hr  style={{border:"1px dashed #eee"}}/>
                {
                   r?.enableSmsPackage &&
                   <div className='cart-right-top-single-one-container' style={{justifyContent:"space-between",}}>
                   <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 15%"}}>SMS Package</p>
                   <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 2% 0 0"}}>{r?.smsPackage?.sellingtotal}</p>
                </div>
                }
                 {
                   r?.enableWhatsappPackage &&
                   <div className='cart-right-top-single-one-container' style={{justifyContent:"space-between",}}>
                   <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 15%"}}>SMS Package</p>
                   <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 2% 0 0"}}>{r?.whatsappPackage?.sellingtotal}</p>
                </div>
                }
               {
                r?.enableBackupPrice &&
                <div className='cart-right-top-single-one-container' style={{justifyContent:"space-between",}}>
                <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 15%"}}>Backup price</p>
                <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 2% 0 0"}}>{r?.backupPrice}</p>
             </div>
               }
               
                {/* <p style={{textAlign:"right",fontSize:"0.8rem",fontWeight:600}}>{r?.orderTotal}</p> */}
                <div className='cart-right-top-single-one-container' style={{justifyContent:"space-between",margin:"4% 0",alignItems
               :"center"}}>
                  <p style={{fontSize:"0.8rem",fontWeight:600,margin:"0 0 0 15%"}}>Pay Amount</p>
                  <input 
                    type="text"  
                    style={{height:"30px",textAlign:"right"}}
                    value={r?.payAmount}
                    onChange={inputChangeFn(i)}
                    />
                </div>
                </div>
             ))
             :
             <div className="empty-cart-container" style={{margin:"20% 2%"}}>
                <img src={emptyCart} alt="" style={{width:"34%"}}/>
                {/* <p>empty cart</p> */}

             </div>
          
          }
         
         </div>
         <div className='cart-right-bottom-container'>
         
           
        
            <div className='cart-right-bottom-single-container'>
               <p>OutletAmount</p>
               <p>{amountState?.totalOutlet}</p>
             </div>
             <div className='cart-right-bottom-single-container'>
               <p>Sub Total</p>
               <p style={{fontSize:"1.1rem",color:"red",fontWeight:"600"}}>{amountState?.subTotal}</p>
             </div>
             <div className='cart-right-bottom-single-container'>
               <p>Tax</p>
               <p>{amountState?.tax?.toFixed(2)}</p>
             </div>
             <div className='cart-right-bottom-single-container'>
               <p>Total Paid</p>
               <p>{amountState?.totalPaid}</p>
             </div>
             <div className='cart-right-bottom-single-container'>
               <p>Balance</p>
               <p>{amountState?.balance}</p>
             </div>
             <div style={{display:"flex",justifyContent:"space-between"}}>
                <button 
                  onClick={purchaseBtnClick}
                 className='buy-now-button-cart' style={{width:"63%"}}>Purchase</button> 
                 {/* <PaymentCheckoutProcessPage subTotal={amountState?.subTotal} /> */}
                 {
                  singlePackageData === undefined ?
                  <button onClick={freeTrialBtnClick} className='free-trial-cart' style={{width:"35%"}}>7 Days Free Trial</button>
                  :
                  <button onClick={ClearBtnFn} className='free-trial-cart' style={{width:"35%"}}>Clear</button>

                 }
             </div>
             <hr  style={{margin:"8% 0 4% 0",borderBottom:"1px solid #fff"}}/>
             <div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
             <p className='footer-text-cart' style={{fontSize:"0.7rem",color:"rgb(189 189 189)"}}> Powerful Features Tailored to Your Business Needs, Through
            advanced automation, real-time data analytics, and customizable
            modules, EYE-T ERP offers unparalleled insights into your business
            operations. Gain complete visibility into inventory management,
            supply chain logistics, financial transactions, HR functions, and
            more, all within a unified platform.</p>
            <hr  style={{margin:"4% 0 4% 0",borderBottom:"1px solid #fff"}}/>

             </div>
            
         </div>
       </div>
    </div>
    <LoadingForm loading={loadingForm}/>

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

   
  </div>
  )
}
