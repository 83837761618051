import { Autocomplete, Dialog, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CountryListWithCode from "../../../Assets/JSON/CountryLIstWithCode.json";
import { addCustomerManual } from "./CustomerRegistrationAPICall";
import { successAlertMessage } from "../../../Js/generalFunnctionnns";
import ErrorSnackbar from "../../SingleCompoents/SnackBars/ErrorSnackbar";
import { viewAllPackageList } from "../../../API/AddPackage/AddPackageAPI";
import { store } from "../../../Redux/Store";
import { customer_register_initial_form } from "./customerRegistrationSlice";

export const CustomerRegistrationForm = () => {
  const navigateTo = useNavigate();
  const popupData = useSelector(
    (state) => state.customerRegistrationSlice.initialForm
  );
  const packageList = useSelector(
    (state) => state.generatePackageIdSlice?.value1
  );

  const formValuesInitial = {
    firstName: "",
    lastName: "",
    companyType: null,
    country: null,
    phone: "",
    websiteName: "",
    userName: "",
    password: "",
  };

  const [formValues, setFormValues] = useState(formValuesInitial);
  const [errorState, setErrorState] = useState({});
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };

  const [customerRegSuccessAlert, setCustomerRegSuccessAlert] = useState(false);

  const getFormValues = (key) => (e, newValue) => {
    const value = e.target ? e.target.value : newValue;
    setFormValues({ ...formValues, [key]: value });

    // Clear error for the field when user starts typing
    if (errorState[key]) {
      setErrorState((prev) => ({ ...prev, [key]: "" }));
    }
  };

  const bodyToSubmitBody = {
    mobile: popupData?.mobile,
    companyName: popupData?.companyName,
    email: popupData?.email,
    info: {
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      companyType: formValues?.companyType,
      country: formValues?.country?.name,
      mobile: formValues?.phone,
      website: formValues?.websiteName,
    },
    username: formValues?.userName,
    password: formValues?.password,
  };

  const validateForm = () => {
    let newErrors = { ...errorState };

    if (!formValues.firstName.trim()) {
      newErrors.firstName = "First name is required";
      setErrorState(newErrors);
      return false;
    }
    if (!formValues.userName.trim()) {
      newErrors.userName = "Username is required";
      setErrorState(newErrors);
      return false;
    }
    if (!formValues.password.trim()) {
      newErrors.password = "Password is required";
      setErrorState(newErrors);
      return false;
    }

    // If no errors exist, return true
    return true;
  };

  const submitBtnFunction = () => {
    if (!validateForm()) return; // Prevent submission if errors exist

    addCustomerManual(
      bodyToSubmitBody,
      setCustomerRegSuccessAlert,
      setSnackBarStates,
      snackBarStates
    );

  };

  const purchaseBtnFn = () => {
    if (!validateForm()) return; // Prevent navigation if errors exist

    store.dispatch(customer_register_initial_form({ initialFormData: bodyToSubmitBody }));
    navigateTo("/dashboard/customerRegistration/cart");
  };

  useEffect(() => {
    viewAllPackageList();
  }, []);

  return (
    <div className="global-container">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="global-single-input auto-complete" style={{ width: "30%" }}>
          <p>First Name</p>
          <TextField
            fullWidth
            error={!!errorState.firstName}
            helperText={errorState.firstName}
            value={formValues?.firstName}
            onChange={getFormValues("firstName")}
            autoComplete="off"
          />
        </div>

        <div className="global-single-input auto-complete" style={{ width: "30%" }}>
          <p>Last Name</p>
          <TextField
            fullWidth
            error={!!errorState.lastName}
            helperText={errorState.lastName}
            value={formValues?.lastName}
            onChange={getFormValues("lastName")}
            autoComplete="off"
          />
        </div>

        <div className="global-single-input auto-complete" style={{ width: "30%" }}>
          <p>Company Type</p>
          <Autocomplete
            fullWidth
            options={[]} // Add valid options
            renderInput={(params) => <TextField {...params} />}
            value={formValues?.companyType}
            onChange={getFormValues("companyType")}
          />
        </div>

        <div className="global-single-input auto-complete" style={{ width: "30%" }}>
          <p>Country</p>
          <Autocomplete
            fullWidth
            options={CountryListWithCode || [""]}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => <TextField {...params} />}
            value={formValues?.country}
            onChange={getFormValues("country")}
            autoComplete="off"
          />
        </div>

        <div className="global-single-input auto-complete" style={{ width: "30%" }}>
          <p>User Name</p>
          <TextField
            fullWidth
            error={!!errorState.userName}
            helperText={errorState.userName}
            value={formValues?.userName}
            onChange={getFormValues("userName")}
            autoComplete="off"
          />
        </div>

        <div className="global-single-input auto-complete" style={{ width: "30%" }}>
          <p>Password</p>
          <TextField
            fullWidth
            type="password"
            error={!!errorState.password}
            helperText={errorState.password}
            value={formValues?.password}
            onChange={getFormValues("password")}
            autoComplete="new-password"
          />
        </div>

        <div className="package-plans-cancel-submit-btn-container" style={{ width: "100%", margin: "2% 0" }}>
          <button
            onClick={purchaseBtnFn}
            className="global-button-contained-black-outlined"
            style={{ color: "#8373f1", borderColor: "#8373f1" }}
          >
            Purchase ERP
          </button>
          <button className="global-button-contained-blue" onClick={submitBtnFunction}>
            Submit
          </button>
        </div>
      </div>

      <ErrorSnackbar open={snackBarStates.error} handleClose={closeSnackbar} message={snackBarStates.message} />

      <Dialog open={customerRegSuccessAlert} maxWidth="lg" onClose={() => setCustomerRegSuccessAlert(false)}>
        <div style={{ width: "30vw", height: "22vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <p style={{ fontSize: "0.8rem", fontWeight: 600, marginTop: "10%" }}>
            Customer Registered Successfully
          </p>
        </div>
      </Dialog>
    </div>
  );
};
